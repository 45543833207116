// Banner Styles
.banner{
	padding: 180px 0 100px;
	.block{
		h1{
			margin-bottom: 13px;
		}
		p{
			font-size: 20px;
			margin-bottom: 30px;
		}
		.video{
			width: 80%;
			margin: 0 auto;
			@include tablet {
				width: 100%;
			}
		}
		ul.clients-logo{
			margin-top: 30px;
			li{
				margin-left: 30px;
				@include mobile {
					margin-bottom: 20px;
				}
				&:first-child{
					margin-left: 0;
				}
			}
		}
	}
}

.slider{
	padding: 180px 0 300px;
	text-align: center;
	position: relative;
	overflow: hidden;
	.block{
		position: relative;
		h1,h3{
			color: $light;
		}
		.download{
			margin-top: 20px;
		}
		.image-content{
			text-align: center;
		    img{
		    	margin-top: 100px;
		    	margin-bottom: -200px;
		    }
		}
	}
	&:before{
	    content: '';
	    position: absolute;
	    bottom: 0;
	    right: 0;
	    border-bottom: 290px solid #fff;
	    border-left: 2000px solid transparent;
	    width: 0;
	}
}

.services{
	.service-block{
		background: $light;
		padding: 30px 40px;
		margin-bottom: 30px;
		&:last-child{
			margin-bottom: 0;
			@include mobile {
				margin-bottom: 30px;
			}
		}
		border-radius: 5px;
		h3 {
			line-height: 30px;
		    text-transform: capitalize;
		    font-size: 16px;
		    font-weight: 500;
		}

		i{
			font-size: 30px;
			color: $primary-color;
			margin-bottom: 15px;
			display:inline-block;

		}
		p{
			margin-bottom: 0;
			font-size:14px;
			line-height:20px;
		}
	}
	.app-preview{
		display: flex;
        justify-content: center !important;
		img{
			height: 500px;
			width: auto;
		}
	}
	@include tablet {
		.col-lg-4.m-auto{
			display: none;
		}
	}
}
.service{
	@extend .hide-overflow;
	.service-thumb{
		@include tablet {
			width: 80%;
			margin: 0 auto;
		}
	}
	.service-box{
		padding: 20px;
		background: $light;
		border-radius: 4px;
		@extend .shadow;
		@include tablet {
			width: 80%;
			margin: 0 auto;
		}
		.service-item{
			text-align: center;
			padding: 10px;
			margin: 20px 0;
			i{
				font-size: 20px;
				color: $primary-color;
				display: inline-block;
				margin-bottom: 10px;
			}
			p{
				font-size: 14px;
			}
		}
	}	
}

.feature{
	.feature-content{
		h2, p{
			margin-bottom: 25px;
			@include tablet {
				text-align: center;
			}
		}
	}
	.testimonial{
		@include tablet {
			text-align: center;
		}
		p{
			font-family: $secondary-font;
			margin-bottom: 10px;
			font-style: italic;
			color: #242424;
		}
		ul.meta{
			li{
				font-size: 12px;
				margin-right: 10px;
				img{
					height: 40px;
					width: 40px;
					border-radius: 100%;
				}
			}
		}
	}
}

// App Features
.app-features{
	.app-feature{
		@include mobile {
			margin-bottom: 30px;
		}
	}
	.app-explore{
		display: flex;
        justify-content: center !important;
	    margin-bottom: 40px;
	}
}

.banner-full{
	.image{
		display: flex;
        justify-content: center;
		img{
			height: 625px;
		}
		@include tablet {
			margin-bottom: 30px;
		}
	}
	.block{
		@include tablet {
			text-align: center;
		}
		.logo{
			margin-bottom: 40px;
		}
		h1{
			margin-bottom: 40px;
		}
		p{
			font-size: 20px;
			margin-bottom: 50px;
		}
		.app{
			margin-bottom: 20px;
		}
	}
}

.video-promo{
	padding: 150px 0;
	.content-block{
		width: 60%;
		margin: 0 auto;
		text-align: center;
		h2{
			font-size: 30px;
			color: $light;
		}
		p{
			margin-bottom: 30px;
		}
		a{
			i.video{
				height: 125px;
			    width: 125px;
			    background: $primary-color;
			    display: inline-block;
			    font-size: 40px;
			    color: $light;
			    text-align: center;
			    line-height: 125px;
			    border-radius: 100%;
			}
			&:focus{
				outline: 0;
			}
		}
	}
}

.testimonial{
	.testimonial-slider{
		.item{
			padding-bottom: 10px;
			.block{
				padding: 40px;
				text-align: center;
				margin: 10px;
		        border-radius: 5px;
				.image{
					margin-top: 30px;
					margin-bottom: 5px;
					width: 100%;
					display: flex;
			        justify-content: center;
					@include tablet {
				        flex-grow: 1;
					}
					img{
						height: 40px;
						width: 40px;
				        border-radius: 100%;
					}	
				}
				p{
					font-family: $secondary-font;
					font-style: italic;
					color: #888888;
				}
				cite{
					font-style: normal;
					font-size: 14px;
					color: #161616;
				}
			}
		}
		// Owl dot Active color overide
		.owl-dots{
			.owl-dot{
				&:hover{
					span{
						background: $primary-color;
					}
				}
				&.active{
					span{
						background: $primary-color;
					}
				}	
			}
		}
	}
}