// Variables
$body-color: #ffffff;
$primary-color: #2e7eed;
$secondary-color: #fff;
$border-color:#cccccc;
$primary-font: 'Open Sans', sans-serif;
$secondary-font: 'Lora', serif;
$text-color: #808080;
$title-color: #757575;
$gray: #808080;
$light-gray: #fafafa;
$dark: #000;
$light: #fff;
$black: #000;
// $icon-font: 'themefisher-font';
