.about{
	.content{
		@include mobile {
			text-align: center;
		}
		h2{
			margin-bottom: 20px;
			text-transform: capitalize;
		}
	}
	.about-slider{
		.item{
			padding: 20px;
		}
		// Owl dot Active color overide
		.owl-dots{
			.owl-dot{
				&:hover{
					span{
						background: $primary-color;
					}
				}
				&.active{
					span{
						background: $primary-color;
					}
				}	
			}
		}
	}
}

.create-stories{
	.block{
		img{
			width: 100%;
			margin-bottom: 20px;
		}
		h3{
			margin-bottom: 10px;
		}
		@include tablet {
			margin-bottom: 30px;
		}
	}
}

.quotes{
	.quote-slider{
		h2{
			font-size: 50px;
		}
		cite{
			margin-left: 150px;
			font-style: normal;
		}
	}
}

.clients{
	padding: 50px 0;
	h3{
		margin-bottom: 30px;
	}
	.client-slider{
		.owl-stage{
			display: flex;
			align-items: center;
		}
	}
}

.investors{
	.block{
		margin-bottom: 30px;
		.image{
			margin-bottom: 20px;
			img{
				width: 100%;
		        border-radius: 8px;
			}
		}
		h3{
			margin-bottom: 0;
			line-height: 1;
		}
		p{
			font-size: 14px;
		}
	}
}